import React from 'react';

import { Grid, Box, Typography, Card, CardContent,
         CardHeader, Button, Link } from '@mui/material';

function MembershipCard ({ key, title, price, length, guestPasses, url }) {
  console.log(title)

  let term = "month"
  let descriptor = " per month"
  switch (length) {
    case "1":
      descriptor = "a month"
      break;
    case "12":
      descriptor = "a year"
      break;
    case "1/4":
      descriptor = "a week"
      break;
    default:
      descriptor = length + " months"
      break;
  }

  const handleRedirect = (url) => {
    window.open(url, 'mozillaTab');
  }


  return (

    <Card variant="outlined" sx={{ textAlign: 'center', width: '30vw', minWidth: 300}}>
        <CardHeader title={title} className={{paddingTop: 3}}></CardHeader>
        <CardContent>
            <Box sx={{paddingBottom: 2}}>
            <Typography variant="h3" component="h2" gutterBottom={true}>
                {price}
                <Typography variant="h6" color="textSecondary" component="span">/ {term}</Typography>
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" component="p">
                {title.startsWith('4x') ? "4 Classes per month" : `Unlimited Classes for ${descriptor}`}
            </Typography>
            {guestPasses > 0
                ? (<Typography color="textSecondary" variant="subtitle1" component="p">
                     {guestPasses} Guest Passes Included!
                    </Typography>)
                : <Typography color="textSecondary" variant="subtitle1" component="p"> &nbsp;
                    </Typography>
            }
            </Box>
            <Grid container>
                <Grid item xs={12} direction={"column"} alignItems={"center"}>
                    <Button variant='contained' onClick={() => handleRedirect(url)}>
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Box mt={1}>
                <Link onClick={() => handleRedirect(url)} color="primary">
                    Learn more
                </Link>
            </Box>
        </CardContent>
    </Card>

  );
}

export default MembershipCard;